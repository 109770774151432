import { soul } from "lib/soul"
import AuthCover from "assets/images/auth-cover.png"
import AuthCoverViolet from "assets/images/auth-cover-violet.png"
import AppleIcon from "assets/images/apple.png"
import GoogleIcon from "assets/images/google.png"
import HuaweiIcon from "assets/images/huawei.png"
import FacebookIcon from "assets/images/facebook.png"
import WhiteBorderDesktop from "assets/images/white-border-final.png"
import axios from "axios"

import { Button, QuestionTitle, Logo } from "components/atoms"
import { FormattedMessage } from "react-intl"
import { isAndroid, isIOS } from "lib/device"
import { Screen, Sexuality } from "config/types"
import { twMerge } from "tailwind-merge"
import { useRef, useState } from "react"
import {
  sendAmplitudeEvent,
  setAmplitudeUserId,
  setAmplitudeUserProperties,
} from "lib/amplitude"

import { ReactComponent as Spinner } from "assets/images/spinner-s.svg"
import { getMetaAttributionParams, trackMetaEvent } from "lib/meta"
import { sendGoogleRegConversion } from "lib/google"
import { trackSnapchatSignup } from "lib/snapchat"

export enum KeycloakOAuthProviders {
  apple = "apple-web",
  google = "google-web-exchange",
  huawei = "huawei-web",
  facebook = "facebook-oidc",
}

type LocationSearch = {
  id_token?: string
  access_token?: string
  code?: string
}

export const HUAWEI_TOKEN_API_URL =
  "https://oauth-login.cloud.huawei.com/oauth2/v3/token"
export const FACEBOOK_TOKEN_API_URL =
  "https://graph.facebook.com/v20.0/oauth/access_token"
export const DEFAULT_GOOGLE_AUTH_URL =
  "https://accounts.google.com/o/oauth2/v2/auth?"
export const DEFAULT_APPLE_AUTH_URL =
  "https://appleid.apple.com/auth/authorize?"
export const DEFAULT_HUAWEI_AUTH_URL =
  "https://oauth-login.cloud.huawei.com/oauth2/v3/authorize?"
export const DEFAULT_FACEBOOK_AUTH_URL =
  "https://www.facebook.com/v20.0/dialog/oauth?"
export const OAUTH_REDIRECT_URL_BASE = `${window.location.origin}/oauth`

const SEXUALITY_FULL = {
  h: "HETERO",
  g: "GAY",
  l: "LESBI",
  b: "BI",
  a: "ASEXUAL",
  q: "QUEER",
}

type AuthButtonProps = React.HTMLProps<HTMLButtonElement> &
  React.ButtonHTMLAttributes<HTMLButtonElement>

function AuthButton(props: AuthButtonProps) {
  const { children, ...rest } = props
  return (
    <Button flat {...rest} theme="bordered" className="w-full m-auto mb-3 py-0">
      <div className="h-[49px] text-base items-center flex justify-center font-sans-bold">
        {children}
      </div>
    </Button>
  )
}

type AuthProps = {
  screen: Screen
  onSuccess: () => void
  redirectToSuccessScreen: () => void
  onButtonClick: (provider: string) => void
  onError: (alias?: string, status?: number) => void
}

export function Auth(props: AuthProps) {
  const [isLoading, setLoading] = useState(false)
  const fbHandlerFlagRef = useRef(false)

  const onOAuthSuccess = async (
    userId: string,
    gender: string | null,
    isNew: boolean,
    method: string,
  ) => {
    setAmplitudeUserId(userId)
    trackMetaEvent("AddToCart")
    trackSnapchatSignup(method)
    sendGoogleRegConversion()
    try {
      if (gender === "f") {
        props.redirectToSuccessScreen()
        return
      }

      let signInEventName = ""
      switch (method) {
        case "google":
          signInEventName = "Google sign in"
          break
        case "apple":
          signInEventName = "OS sign in"
          break
        case "huawei":
          signInEventName = "Huawei sign in"
          break
        case "facebook":
          signInEventName = "FB sign in"
          break
      }

      sendAmplitudeEvent(signInEventName, {
        source: "marketing",
      })

      if (!gender) {
        const sexuality = (localStorage.getItem("sexuality") ||
          "h") as Sexuality
        try {
          const newGender = localStorage.getItem("gender") || "m"
          await soul.me.update({
            filterable: {
              gender: newGender,
              sexuality,
            },
          })

          setAmplitudeUserProperties({ gender: newGender.toUpperCase() })

          if (isNew) {
            const searchParams = new URL(location.href).searchParams
            const isFacebookCampaign = (
              searchParams.get("utm_source") || searchParams.get("pid")
            )
              ?.toLowerCase()
              .includes("fb")
            sendAmplitudeEvent("User created", {
              gender: newGender.toUpperCase(),
              sexuality: SEXUALITY_FULL[sexuality],
              utm_source:
                searchParams.get("utm_source") || searchParams.get("pid"),
              utm_campaign:
                searchParams.get("utm_campaign") || searchParams.get("c"),
              ...(isFacebookCampaign && getMetaAttributionParams()),
            })
          }

          if (newGender === "f") {
            props.redirectToSuccessScreen()
            return
          }
        } catch {}
      }
      await soul.purchases.getUserAccess()
      props.redirectToSuccessScreen()
    } catch {
      const { items = [] } = await soul.purchases.getOwnedProducts()

      if (
        !items.some((item: { name: string }) =>
          item.name.includes("membership"),
        )
      ) {
        // user had no subscriptions
        localStorage.setItem("uhns", "1")
      } else {
        localStorage.removeItem("uhns")
      }

      props.onSuccess()
    }
  }

  const loginViaGoogle = () => {
    props.onButtonClick("google")
    const params = {
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || "",
      redirect_uri: `${OAUTH_REDIRECT_URL_BASE}/google`,
      scope: "profile email openid",
      include_granted_scopes: "true",
      response_type: "token",
    }

    const oAuthWindow = window.open(
      DEFAULT_GOOGLE_AUTH_URL + new URLSearchParams(params),
      "",
      "width=700,height=600",
    )

    //@ts-ignore
    window.onOAuthSuccess = async (search: LocationSearch) => {
      oAuthWindow?.close()
      await handleGoogleOAuth(search)
    }
  }

  const loginViaApple = () => {
    props.onButtonClick("apple")
    const params = {
      client_id: "org.getpure.pure-web",
      redirect_uri: `${OAUTH_REDIRECT_URL_BASE}/apple`,
      response_type: "code id_token",
      response_mode: "web_message",
      scope: "email",
    }
    const url = DEFAULT_APPLE_AUTH_URL + new URLSearchParams(params)
    const oAuthWindow = window.open(url, "", "width=700,height=600")
    //@ts-ignore
    window.onOAuthSuccess = async (search: LocationSearch) => {
      oAuthWindow?.close()
      await handleAppleOAuth(search)
    }

    const listener = async (event: MessageEvent) => {
      if (event.origin === "https://appleid.apple.com" && oAuthWindow) {
        const { id_token } = JSON.parse(event.data).data.authorization
        oAuthWindow.close()

        try {
          setLoading(true)
          const result = await soul.auth.keycloak.exchangeToken({
            method: KeycloakOAuthProviders.apple,
            idToken: id_token,
          })
          oAuthWindow?.close()
          await onOAuthSuccess(
            result.me.id,
            result.me.parameters.filterable.gender,
            result.is201 || result.is_new,
            "apple",
          )
        } catch (e: any) {
          const alias = e?.response?.body?.error?.alias
          const status = e?.status
          props.onError(alias, status)
          setLoading(false)
        }
      }
    }
    window.addEventListener("message", listener)
  }

  const loginViaHuawei = () => {
    props.onButtonClick("huawei")
    const params = {
      response_type: "code",
      access_type: "offline",
      client_id: process.env.REACT_APP_HUAWEI_CLIENT_ID || "",
      scope: "openid email profile",
      redirect_uri: `${OAUTH_REDIRECT_URL_BASE}/huawei`,
    }

    const oAuthWindow = window.open(
      DEFAULT_HUAWEI_AUTH_URL + new URLSearchParams(params),
      "",
      "width=700,height=600",
    )

    //@ts-ignore
    window.onOAuthSuccess = async (search: LocationSearch) => {
      oAuthWindow?.close()
      await handleHuaweiOAuth(search)
    }
  }

  async function handleStorageUpdate() {
    if (localStorage.getItem("fb_auth_search") && !fbHandlerFlagRef.current) {
      const search = JSON.parse(
        localStorage.getItem("fb_auth_search") || "",
      ) as LocationSearch
      await handleFacebookOAuth(search)
      fbHandlerFlagRef.current = true
      window.removeEventListener("storage", handleStorageUpdate)
    }
  }

  const loginViaFacebook = () => {
    props.onButtonClick("facebook")
    const params = {
      response_type: "code",
      client_id: process.env.REACT_APP_FB_CLIENT_ID || "",
      scope: "openid,email",
      redirect_uri: `${OAUTH_REDIRECT_URL_BASE}/facebook`,
    }

    const oAuthWindow = window.open(
      DEFAULT_FACEBOOK_AUTH_URL + new URLSearchParams(params),
      "",
      "width=700,height=600",
    )
    window.removeEventListener("storage", handleStorageUpdate)
    fbHandlerFlagRef.current = false
    window.addEventListener("storage", handleStorageUpdate)

    //@ts-ignore
    window.onOAuthSuccess = async (search: LocationSearch) => {
      oAuthWindow?.close()
      await handleFacebookOAuth(search)
    }
  }

  const handleGoogleOAuth = async (search: LocationSearch) => {
    let result
    try {
      setLoading(true)
      const { id_token, access_token } = search

      const response = await soul.auth.keycloak.exchangeToken({
        method: KeycloakOAuthProviders.google,
        accessToken: access_token,
        idToken: id_token,
      })

      result = {
        ...response,
        ...response.authorization,
      }
      await onOAuthSuccess(
        result.me.id,
        result.me.parameters.filterable.gender,
        result.is201 || result.is_new,
        "google",
      )
    } catch (e: any) {
      const alias = e?.response?.body?.error?.alias
      const status = e?.status
      props.onError(alias, status)
      setLoading(false)
    }
  }

  const handleAppleOAuth = async (search: LocationSearch) => {
    let result
    try {
      setLoading(true)
      const { id_token } = search

      const response = await soul.auth.keycloak.exchangeToken({
        method: KeycloakOAuthProviders.apple,
        idToken: id_token,
      })

      result = {
        ...response,
        ...response.authorization,
      }
      await onOAuthSuccess(
        result.me.id,
        result.me.parameters.filterable.gender,
        result.is201 || result.is_new,
        "apple",
      )
    } catch (e: any) {
      const alias = e?.response?.body?.error?.alias
      const status = e?.status
      props.onError(alias, status)
      setLoading(false)
    }
  }

  const handleHuaweiOAuth = async (search: LocationSearch) => {
    let result

    try {
      setLoading(true)
      const { code } = search
      const redirectUri = `${window.location.origin}/oauth/huawei`

      const { data } = await axios.post(
        HUAWEI_TOKEN_API_URL,
        {
          grant_type: "authorization_code",
          code,
          client_id: process.env.REACT_APP_HUAWEI_CLIENT_ID,
          client_secret: process.env.REACT_APP_HUAWEI_AUTH_CLIENT_SECRET,
          redirect_uri: redirectUri,
        },
        {
          headers: {
            Host: "oauth-login.cloud.huawei.com",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      )

      const response = await soul.auth.keycloak.exchangeToken({
        method: KeycloakOAuthProviders.huawei,
        idToken: data.id_token,
      })

      result = {
        ...response,
        ...response.authorization,
      }

      await onOAuthSuccess(
        result.me.id,
        result.me.parameters.filterable.gender,
        result.is201 || result.is_new,
        "huawei",
      )
    } catch (e: any) {
      const alias = e?.response?.body?.error?.alias
      const status = e?.status
      props.onError(alias, status)
      setLoading(false)
    }
  }

  const handleFacebookOAuth = async (search: LocationSearch) => {
    let result
    try {
      setLoading(true)
      const { code } = search

      const {
        data: { id_token },
      } = await axios.get(FACEBOOK_TOKEN_API_URL, {
        params: {
          code,
          client_id: process.env.REACT_APP_FB_CLIENT_ID,
          client_secret: process.env.REACT_APP_FB_CLIENT_SECRET,
          redirect_uri: `${OAUTH_REDIRECT_URL_BASE}/facebook`,
        },
      })

      const response = await soul.auth.keycloak.exchangeToken({
        method: KeycloakOAuthProviders.facebook,
        idToken: id_token,
      })

      result = {
        ...response,
        ...response.authorization,
      }
      await onOAuthSuccess(
        result.me.id,
        result.me.parameters.filterable.gender,
        result.is201 || result.is_new,
        "facebook",
      )
    } catch (e: any) {
      const alias = e?.response?.body?.error?.alias
      const status = e?.status
      props.onError(alias, status)
      setLoading(false)
    }
  }

  return (
    <div className="h-full w-full background lg:absolute lg:left-0 lg:top-0 lg:flex lg:flex-col lg:bg-violet-page lg:bg-cover lg:bg-center">
      <div className="hidden lg:block pt-8 pl-10">
        <Logo className="h-5" />
      </div>
      <div className="lg:flex lg:grow lg:gap-16 lg:items-center lg:justify-center">
        <img
          src={AuthCoverViolet}
          className="hidden lg:inline-block w-[409px]"
        />
        <div className="text-center px-4 h-full flex flex-col max-w-screen-sm mx-auto lg:mx-0 lg:h-auto lg:max-w-sm lg:px-8 lg:pb-4 lg:relative">
          <img
            src={WhiteBorderDesktop}
            className="hidden lg:inline-block w-full h-full absolute top-0 left-0"
          />
          <QuestionTitle
            id="sign_up.title"
            className="lg:mt-6 lg:mb-1 lg:font-serif lg:text-[28px] lg:leading-9 relative"
          />
          <div
            className={twMerge(
              "grow flex items-center lg:hidden",
              isIOS
                ? "height-770:h-[270px] height-770:mt-4 height-650:h-[250px] height-600:mh-[210px] height-575:h-[190px]"
                : "height-575:h-[150px] height-600:h-[190px] height-650:h-[210px] height-730:h-[230px] height-770:h-[250px]",
            )}
          >
            <img
              src={AuthCover}
              className="height-770:max-h-[100%] height-770:mx-auto height-770:h-full"
            />
          </div>
          <div
            className={twMerge(
              "mt-10 relative height-700:mt-6 lg:mb-5",
              isIOS &&
                "height-770:flex height-770:flex-col height-770:justify-end",
            )}
          >
            <div
              className={twMerge(
                "absolute w-full h-full bg-white z-10 flex items-center justify-center",
                !isLoading && "hidden",
              )}
            >
              <Spinner className="animate-spin" />
            </div>
            <AuthButton onClick={loginViaGoogle}>
              <img src={GoogleIcon} className="h-[20px] mr-2 mb-0.5" />
              <FormattedMessage id="sign_up.google" />
            </AuthButton>
            {props.screen.showFacebook && (
              <AuthButton onClick={loginViaFacebook}>
                <img src={FacebookIcon} className="h-[20px] mr-2" />
                <FormattedMessage id="sign_up.facebook" />
              </AuthButton>
            )}
            {!isAndroid && (
              <AuthButton onClick={loginViaApple}>
                <img src={AppleIcon} className="h-[20px] mr-2 mb-0.5" />
                <FormattedMessage id="sign_up.apple" />
              </AuthButton>
            )}
            {!isIOS && (
              <AuthButton onClick={loginViaHuawei}>
                <img src={HuaweiIcon} className="h-[20px] mr-2" />
                <FormattedMessage id="sign_up.huawei" />
              </AuthButton>
            )}
          </div>
          <div className="mt-2 pb-4 height-770:mt-0 relative">
            <div className="text-base text-black">
              <FormattedMessage id="sign_up.restriction" />
            </div>
            <div className="text-[11px] leading-[16px] mt-0.5 text-black lg:mt-2">
              <FormattedMessage
                id="sign_up.agreement"
                values={{
                  terms: (
                    <a
                      href="https://pure.app/content/en/terms"
                      target="_blank"
                      className="text-gray-200"
                    >
                      <FormattedMessage id="sign_up.terms" />
                    </a>
                  ),
                  privacy: (
                    <a
                      href="https://pure.app/content/en/privacy"
                      target="_blank"
                      className="text-gray-200"
                    >
                      <FormattedMessage id="sign_up.privacy" />
                    </a>
                  ),
                  safety: (
                    <a
                      href="https://pure.app/content/en/security"
                      target="_blank"
                      className="text-gray-200"
                    >
                      <FormattedMessage id="sign_up.safety" />
                    </a>
                  ),
                  guidelines: (
                    <a
                      href="https://pure.app/content/en/guidelines"
                      target="_blank"
                      className="text-gray-200"
                    >
                      <FormattedMessage id="sign_up.guidelines" />
                    </a>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
