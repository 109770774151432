import headerImgEn from "assets/images/v57/header-en.webp"
import infoImgEn from "assets/images/v57/info-en.png"
import headerImgFr from "assets/images/v57/header-fr.webp"
import infoImgFr from "assets/images/v57/info-fr.webp"
import headerImgDe from "assets/images/v57/header-de.webp"
import infoImgDe from "assets/images/v57/info-de.webp"

export const v57ConfigImages: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
    fr: headerImgFr,
    de: headerImgDe,
  },
  info: {
    en: infoImgEn,
    fr: infoImgFr,
    de: infoImgDe,
  },
}
