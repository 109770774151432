import headerImgEn from "assets/images/v73/header-en.webp"
import infoImgEn from "assets/images/v73/info-en.webp"
import headerImgFr from "assets/images/v73/header-fr.webp"
import infoImgFr from "assets/images/v73/info-fr.webp"
import headerImgDe from "assets/images/v73/header-de.webp"
import infoImgDe from "assets/images/v73/info-de.webp"

export const v73ConfigImages: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
    fr: headerImgFr,
    de: headerImgDe,
  },
  info: {
    en: infoImgEn,
    fr: infoImgFr,
    de: infoImgDe,
  },
}
