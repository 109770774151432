import headerImgEn from "assets/images/v72/header-en.webp"
import infoImgEn from "assets/images/v72/info-en.webp"
import headerImgFr from "assets/images/v72/header-fr.webp"
import infoImgFr from "assets/images/v72/info-fr.png"
import headerImgDe from "assets/images/v72/header-de.webp"
import infoImgDe from "assets/images/v72/info-de.png"

export const v72ConfigImages: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
    fr: headerImgFr,
    de: headerImgDe,
  },
  info: {
    en: infoImgEn,
    fr: infoImgFr,
    de: infoImgDe,
  },
}
