export const trackMetaEvent = (
  eventName: string,
  eventParams?: Record<string, number | string>,
) => {
  if (
    !window.location.origin.includes("test") &&
    !window.location.origin.includes("localhost")
  ) {
    if (eventName !== "Lead" || !sessionStorage.getItem("fb_lead_tracked")) {
      //@ts-ignore
      if (typeof window.fbq === "function") {
        //@ts-ignore
        window.fbq("track", eventName, eventParams)

        if (eventName === "Lead") {
          sessionStorage.setItem("fb_lead_tracked", "1")
        }
      }
    }
  }
}

export function getMetaAttributionParams() {
  const searchParams = new URL(location.href).searchParams
  const cookies = document.cookie
    .split("; ")
    .map((element) => element.split("="))
    .reduce<Record<string, string>>((acc, curr) => {
      acc[curr[0]] = curr.slice(1).join("=")
      return acc
    }, {})

  const fbclid = searchParams.get("fbclid")
  const fbp = cookies["_fbp"]
  const fbc = cookies["_fbc"]

  return {
    facebook_click_id: fbclid,
    facebook_fbp: fbp,
    facebook_fbc: fbc,
  }
}
