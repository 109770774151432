import headerImgEn from "assets/images/v9-nd/header-en.webp"
import infoImgEn from "assets/images/v9-nd/info-en.webp"
import headerImgFr from "assets/images/v9-nd/header-fr.webp"
import infoImgFr from "assets/images/v9-nd/info-fr.png"
import headerImgDe from "assets/images/v9-nd/header-de.webp"
import infoImgDe from "assets/images/v9-nd/info-de.webp"

export const v9ndConfigImages: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
    fr: headerImgFr,
    de: headerImgDe,
  },
  info: {
    en: infoImgEn,
    fr: infoImgFr,
    de: infoImgDe,
  },
}
