import { IntlProvider } from "react-intl"
import { Route, Routes, useParams } from "react-router-dom"
import { StartingScreen, Survey } from "components/organisms"

import { SURVEY_CONFIG_V1 } from "config/v1"
import enLocales from "locales/en.json"
import ruLocales from "locales/ru.json"
import frLocales from "locales/fr.json"
import deLocales from "locales/de.json"
import { SURVEY_CONFIG_V2 } from "config/v2"
import { SURVEY_CONFIG_CHAT1 } from "config/chat-1"
import { SURVEY_CONFIG_CHAT2 } from "config/chat-2"
import { SURVEY_CONFIG_CHAT3 } from "config/chat-3"
import { SURVEY_CONFIG_CHAT4 } from "config/chat-4"
import { SURVEY_CONFIG_MAP1 } from "config/map-1"
import { SURVEY_CONFIG_MAP2 } from "config/map-2"
import { StorePage } from "components/organisms/store-page"
import { PAYGATE_CONFIG } from "config/paygate"
import { SURVEY_CONFIG_V43 } from "config/v43"
import { v55ConfigImages } from "config/v55"
import { PageWithCover } from "components/organisms/page-with-cover"
import { v57ConfigImages } from "config/v57"
import { v9ndConfigImages } from "config/v9-nd"
import { v9ndpbConfigImages } from "config/v9-nd-pb"
import { v57ndConfigImages } from "config/v57-nd"
import { v57ndpbConfigImages } from "config/v57-nd-pb"
import { v71ConfigImages } from "config/v71"
import { v72ConfigImages } from "config/v72"
import { v73ConfigImages } from "config/v73"

const MESSAGES: Record<string, any> = {
  en: enLocales,
  ru: ruLocales,
  fr: frLocales,
  de: deLocales,
}

const LOCALIZED_SURVEYS = ["nv3", "nv9", "nv9-nd", "nv57", "nv72", "nv73"]

export default function App() {
  const { locale = "en", ...rest } = useParams()
  const survey = rest["*"]
  const messages = LOCALIZED_SURVEYS.includes(survey || "")
    ? MESSAGES[locale] || MESSAGES.en
    : MESSAGES.en

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
      <Routes>
        <Route
          path="nv1"
          element={
            <Survey
              surveyId="nv1"
              steps={SURVEY_CONFIG_V1}
              StartScreen={StartingScreen}
            />
          }
        />
        <Route
          path="nv2"
          element={
            <Survey
              surveyId="nv2"
              steps={SURVEY_CONFIG_V2}
              StartScreen={StartingScreen}
            />
          }
        />
        <Route
          path="av2"
          element={
            <Survey
              surveyId="av2"
              steps={SURVEY_CONFIG_V2}
              StartScreen={StartingScreen}
            />
          }
        />
        <Route
          path="chat_v1"
          element={<Survey surveyId="chat_v1" steps={SURVEY_CONFIG_CHAT1} />}
        />
        <Route
          path="chat_v2"
          element={<Survey surveyId="chat_v2" steps={SURVEY_CONFIG_CHAT2} />}
        />
        <Route
          path="chat_v3"
          element={<Survey surveyId="chat_v3" steps={SURVEY_CONFIG_CHAT3} />}
        />
        <Route
          path="chat_v4"
          element={<Survey surveyId="chat_v4" steps={SURVEY_CONFIG_CHAT4} />}
        />
        <Route
          path="map_v1"
          element={<Survey surveyId="map_v1" steps={SURVEY_CONFIG_MAP1} />}
        />
        <Route
          path="map_v2"
          element={<Survey surveyId="map_v2" steps={SURVEY_CONFIG_MAP2} />}
        />
        <Route path="nv3" element={<StorePage surveyId="nv3" />} />
        <Route path="nv9" element={<StorePage surveyId="nv9" />} />
        <Route
          path="nv55"
          element={<StorePage surveyId="nv55" images={v55ConfigImages} />}
        />
        <Route
          path="nv57"
          element={
            <StorePage
              surveyId="nv57"
              images={v57ConfigImages}
              buttonClassName="mt-3 rounded-[16px]"
            />
          }
        />
        <Route
          path="nv71"
          element={<StorePage surveyId="nv71" images={v71ConfigImages} />}
        />
        <Route
          path="nv72"
          element={
            <StorePage
              surveyId="nv72"
              images={v72ConfigImages}
              buttonClassName="mt-3 rounded-[16px]"
            />
          }
        />
        <Route
          path="nv73"
          element={
            <StorePage
              surveyId="nv73"
              images={v73ConfigImages}
              buttonClassName="mt-3 rounded-[16px]"
            />
          }
        />
        <Route
          path="nv9-nd"
          element={<StorePage surveyId="nv9-nd" images={v9ndConfigImages} />}
        />
        <Route
          path="nv9-nd-pb"
          element={
            <StorePage surveyId="nv9-nd-pb" images={v9ndpbConfigImages} />
          }
        />
        <Route
          path="nv57-nd"
          element={
            <StorePage
              surveyId="nv57-nd"
              images={v57ndConfigImages}
              buttonClassName="mt-3 rounded-[16px]"
            />
          }
        />
        <Route
          path="nv57-nd-pb"
          element={
            <StorePage
              surveyId="nv57-nd-pb"
              images={v57ndpbConfigImages}
              buttonClassName="mt-3 rounded-[16px]"
            />
          }
        />
        <Route path="nv49" element={<PageWithCover surveyId="nv49" />} />
        <Route
          path="paygate"
          element={
            <Survey
              surveyId="paygate"
              steps={PAYGATE_CONFIG}
              StartScreen={StartingScreen}
            />
          }
        />
        <Route
          path="nv43"
          element={
            <Survey
              surveyId="nv43"
              steps={SURVEY_CONFIG_V43}
              StartScreen={StartingScreen}
            />
          }
        />
      </Routes>
    </IntlProvider>
  )
}
